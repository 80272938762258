@import "../_variables.less";

.list {
  display: flex;
  flex-direction: column;

  &__header,
  &__footer {
    display: flex;
    flex-direction: column;
  }

  &__header {
    font-weight: bold;
  }

  &__search {
    margin-top: 5px;
    input[type="text"] {
      margin: 0px;
    }
  }

  &__content {
    display: flex;
    flex: 1;
    flex-direction: column;

    &--no-data {
      padding-top: 10px;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    color: @workspace-color;
    padding: 10px 5px;

    > div {
      line-height: 1.5em;
      text-overflow: ellipsis;
    }

    > div:nth-child(1) {
      font-weight: bold;
    }

    &--right {
      float: right;
    }

    .active {
      background: lighten(@workspace-hover-color, 45%);
    }

    &:hover {
      cursor: pointer;
      background: lighten(@workspace-hover-color, 45%);
    }

    div:focus {
      outline: none;
    }
  }

  &__itemDetail {
    font-size: 0.8em;
  }

  &__item--active {
    background: lighten(@workspace-link-color, 5%)
  }
}
