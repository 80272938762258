.rotate(@deg: 90) {
  /* Safari */
  -webkit-transform: rotate(@deg * 1deg);
  /* Firefox */
  -moz-transform: rotate(@deg * 1deg);
  /* IE10+ */
  transform: rotate(@deg * 1deg);
  /* Opera */
  -o-transform: rotate(@deg * 1deg);
}
