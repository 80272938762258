* {
  margin: 0;
  padding: 0;
}
body {
  font-family: sans-serif;
  height: 100vh;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #222f6b;
}
a {
  color: #e95420;
  text-decoration: none;
}
ul,
li {
  list-style-type: none;
  list-style-position: inside;
}
table {
  width: 100%;
  margin-top: 5px;
}
td {
  text-align: left;
  vertical-align: top;
}
td button,
td input[type="text"],
td input[type="password"],
td input[type="date"],
td input[type="datetime"],
td input[type="email"],
td input[type="number"],
td input[type="search"],
td input[type="time"],
td input[type="url"],
td textarea,
td select {
  margin-bottom: 0;
  padding: 0;
  -webkit-appearance: menulist-button;
  height: 35px;
}
.title {
  width: 100%;
  font-size: 1.2em;
  font-weight: bold;
  padding-bottom: 5px;
  margin-bottom: 10px;
  border-bottom: 1px solid #222f6b;
}
/* width */
::-webkit-scrollbar {
  width: 6px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #3b51b8;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #222f6b;
}
.button,
a.button,
button,
[type=button],
[type=submit] {
  display: inline-block;
  background: #f0b02c;
  border: 1px solid #f0b02c;
  color: white;
  text-decoration: none;
  padding: 0.25em 1em;
  vertical-align: middle;
  white-space: nowrap;
  cursor: pointer;
  font-size: 0.8em;
  border-radius: 18px;
}
.button:hover,
a.button:hover,
button:hover,
[type=button]:hover,
[type=submit]:hover {
  background: #f3c25c;
}
.button:disabled,
a.button:disabled,
button:disabled,
[type=button]:disabled,
[type=submit]:disabled {
  background: #ffffff;
}
.button--secondary {
  background: #f7d48b;
  border: 1px solid #f7d48b;
}
.button--secondary:hover {
  background: #fae5bb;
}
.button--squared {
  padding: 0.5em 0.75em;
  border-radius: 2px;
}
.button--disabled {
  background: #ffffff;
}
.form fieldset {
  border: none;
  margin-bottom: 10px;
}
.form legend:hover {
  cursor: pointer;
}
.form label {
  display: block;
  margin-bottom: 2px;
  font-size: 0.8em;
}
.form input[type="text"],
.form input[type="password"],
.form input[type="date"],
.form input[type="datetime"],
.form input[type="email"],
.form input[type="number"],
.form input[type="search"],
.form input[type="time"],
.form input[type="url"],
.form textarea,
.form select {
  background: rgba(255, 255, 255, 0.1);
  border: none;
  border-radius: 4px;
  margin: 0;
  outline: 0;
  padding: 5px;
  width: 100%;
  background-color: #e8eeef;
  box-sizing: border-box;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.03) inset;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.03) inset;
}
.form input[type="text"]:focus,
.form input[type="password"]:focus,
.form input[type="date"]:focus,
.form input[type="datetime"]:focus,
.form input[type="email"]:focus,
.form input[type="number"]:focus,
.form input[type="search"]:focus,
.form input[type="time"]:focus,
.form input[type="url"]:focus,
.form textarea:focus,
.form select:focus {
  background: #d2d9dd;
}
.form select {
  -webkit-appearance: menulist-button;
  height: 35px;
}
.form .number {
  height: 30px;
  width: 30px;
  display: inline-block;
  font-size: 0.8em;
  margin-right: 4px;
  line-height: 30px;
  text-align: center;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.2);
  border-radius: 15px 15px 15px 0px;
}
.form__validation--error {
  font-size: 0.8em;
  font-weight: bold;
  color: #e6003c;
  margin-bottom: 10px;
}
.form__validation-summary {
  color: #e6003c;
  margin: 10px 0px;
}
.form__validation-summary li {
  margin: 0;
}
.editor {
  display: block;
  padding-bottom: 10px;
}
.list {
  display: flex;
  flex-direction: column;
}
.list__header,
.list__footer {
  display: flex;
  flex-direction: column;
}
.list__header {
  font-weight: bold;
}
.list__search {
  margin-top: 5px;
}
.list__search input[type="text"] {
  margin: 0px;
}
.list__content {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.list__content--no-data {
  padding-top: 10px;
}
.list__item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  color: #333;
  padding: 10px 5px;
}
.list__item > div {
  line-height: 1.5em;
  text-overflow: ellipsis;
}
.list__item > div:nth-child(1) {
  font-weight: bold;
}
.list__item--right {
  float: right;
}
.list__item .active {
  background: #e3e7eb;
}
.list__item:hover {
  cursor: pointer;
  background: #e3e7eb;
}
.list__item div:focus {
  outline: none;
}
.list__itemDetail {
  font-size: 0.8em;
}
.list__item--active {
  background: #eb6637;
}
.pane {
  display: grid;
  grid-template-columns: 360px 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: 'list detail';
  height: 100vh;
}
.pane__list {
  grid-area: list;
  padding: 5px;
  overflow-y: auto;
  border-right: 1px solid #222f6b;
}
.pane__detail {
  grid-area: detail;
  padding: 5px;
  overflow-y: auto;
}
.pane--onlydetail {
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: 'detail';
}
@media (max-width: 1024px) {
  .pane {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas: 'list' 'detail';
    height: 100vh;
  }
  .pane--onlydetail {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: 'detail';
  }
}
.tabs {
  width: 100%;
  margin: 10px 0px;
}
.tabs ul li {
  display: inline;
  font-weight: bold;
  cursor: pointer;
}
.tabs__tab {
  margin-left: 10px;
}
.tabs .tab__title {
  margin: 0px 10px;
  padding: 0px 5px;
}
.tabs .tab__title--active {
  border-bottom: 3px solid #222f6b;
}
.button__bar {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}
.button__bar > button,
.button__bar > .button,
.button__bar > .button-wrapper {
  position: relative;
  float: left;
  margin-right: 1px;
  margin-bottom: 1px;
  border-radius: 0;
}
.button__bar > button:first-child,
.button__bar > .button:first-child,
.button__bar > .button-wrapper:first-child {
  border-top-left-radius: 18px;
  border-bottom-left-radius: 18px;
}
.button__bar > button:last-child,
.button__bar > .button:last-child,
.button__bar > .button-wrapper:last-child {
  margin-right: 0;
  border-top-right-radius: 18px;
  border-bottom-right-radius: 18px;
}
.multiselect-dropdown {
  position: relative;
}
.multiselect-dropdown .dropdown-btn {
  display: inline-block;
  border: 1px solid #adadad;
  width: 100%;
  min-height: 25px;
  margin-bottom: 0;
  font-size: 0.9em;
  font-weight: normal;
  text-align: left;
  vertical-align: middle;
  background-color: #e8eeef;
  border-radius: 4px;
  cursor: pointer;
}
.multiselect-dropdown .dropdown-btn .selected-item {
  border: 1px solid #222f6b;
  margin: 4px;
  padding: 0 4px;
  border-radius: 2px;
  background: #222f6b;
  color: #fff;
  float: left;
}
.multiselect-dropdown .dropdown-btn .selected-item a {
  text-decoration: none;
}
.multiselect-dropdown .dropdown-btn .selected-item:hover {
  box-shadow: 1px 1px #959595;
}
.multiselect-dropdown .dropdown-btn .no-item-selected {
  margin-top: 2px;
  margin-bottom: 2px;
  padding: 1px 5px;
  float: left;
}
.multiselect-dropdown .dropdown-btn .dropdown-down {
  display: inline-block;
  top: 5px;
  width: 0;
  height: 0;
  border-top: 5px solid #adadad;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}
.multiselect-dropdown .dropdown-btn .dropdown-up {
  display: inline-block;
  width: 0;
  height: 0;
  border-bottom: 5px solid #adadad;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}
.multiselect-dropdown .disabled > span {
  background-color: #eceeef;
}
.dropdown-list {
  position: absolute;
  width: 100%;
  z-index: 1000;
  border: 1px solid #ccc;
  background: #fff;
  box-shadow: 0px 1px 5px #959595;
}
.dropdown-list ul {
  padding: 0px;
  list-style: none;
  overflow-y: auto;
  margin: 0px;
  max-height: 200px;
}
.dropdown-list li {
  padding: 5px;
  cursor: pointer;
  text-align: left;
}
.dropdown-list .filter-textbox {
  border-bottom: 1px solid #ccc;
  position: relative;
  display: flex;
}
.dropdown-list .filter-textbox input {
  border: 0;
  margin: 0;
}
.dropdown-list .filter-textbox input:focus {
  outline: none;
}
.dropdown-list .filter-textbox button {
  margin-left: 5px;
}
.multiselect-item-checkbox input[type='checkbox'] {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.multiselect-item-checkbox input[type='checkbox']:focus + div:before,
.multiselect-item-checkbox input[type='checkbox']:hover + div:before {
  border-color: #222f6b;
  background-color: #f2f2f2;
}
.multiselect-item-checkbox input[type='checkbox'] + div {
  position: relative;
  padding-left: 2em;
  vertical-align: middle;
  user-select: none;
  cursor: pointer;
  margin: 0px;
}
.multiselect-item-checkbox input[type='checkbox'] + div:before {
  box-sizing: content-box;
  content: '';
  color: #222f6b;
  position: absolute;
  top: 50%;
  left: 0;
  width: 14px;
  height: 14px;
  margin-top: -9px;
  border: 2px solid #222f6b;
  text-align: center;
  transition: all 0.1s ease;
}
.multiselect-item-checkbox input[type='checkbox'] + div:after {
  background-color: #222f6b;
  content: '';
  position: absolute;
  box-sizing: content-box;
  top: 50%;
  left: 4px;
  width: 10px;
  height: 10px;
  margin-top: -5px;
  transform: scale(0);
  transform-origin: 50%;
  transition: transform 100ms ease-out;
}
.multiselect-item-checkbox input[type='checkbox'] + div:after {
  background-color: transparent;
  top: 50%;
  left: 4px;
  width: 8px;
  height: 3px;
  margin-top: -4px;
  border-style: solid;
  border-color: #fff;
  border-width: 0 0 3px 3px;
  border-image: none;
  transform: rotate(-45deg) scale(0);
}
.multiselect-item-checkbox input[type='checkbox']:checked + div:after {
  content: '';
  transform: rotate(-45deg) scale(1);
  transition: transform 100ms ease-out;
}
.multiselect-item-checkbox input[type='checkbox']:checked + div:before {
  background: #222f6b;
  animation: borderscale 100ms ease-in;
}
@keyframes borderscale {
  50% {
    box-shadow: 0 0 0 2px #222f6b;
  }
}
.cdk-overlay-container {
  background-color: #fff;
  opacity: 0.97 !important;
}
.popover {
  background-color: #fff;
  box-shadow: 0 2px 14px #3b51b8;
  border-radius: 5px;
  padding: 1rem;
}
.sidebar {
  grid-area: sidebar;
  display: flex;
  flex-direction: column;
  background: #222f6b;
  width: 360px;
  overflow-y: auto;
}
.sidebar__header {
  display: flex;
  padding: 10px 20px;
  font-size: 1em;
  font-weight: bold;
  letter-spacing: 0.5em;
  color: whitesmoke;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
}
.sidebar__header > button {
  cursor: pointer;
}
.sidebar__header img {
  object-fit: cover;
  border-radius: 50%;
  width: 140px;
  height: 140px;
}
.sidebar__content {
  display: flex;
  flex: 1;
  text-align: left;
  line-height: 2.5em;
  overflow-y: auto;
}
.sidebar__content ul {
  width: 100%;
  font-size: 0.8em;
}
.sidebar__content ul li {
  padding: 0px 20px;
}
.sidebar__content ul li a,
.sidebar__content ul li span {
  color: whitesmoke;
  display: block;
  font-weight: bold;
  letter-spacing: 0.25em;
  text-transform: uppercase;
  width: 100%;
}
.sidebar__content ul li ul li,
.sidebar__content ul li ul .active {
  margin: 0px -20px;
  padding: 0px 40px;
}
.sidebar__content ul li ul li:hover {
  margin: 0px -20px;
  padding: 0px 40px;
}
.sidebar__content ul .menu__item:hover {
  cursor: pointer;
  background: #2e4092;
}
.sidebar__content ul .active {
  background: #2e4092;
}
.sidebar__footer {
  display: flex;
  color: whitesmoke;
  text-align: left;
  line-height: 2.5em;
}
.sidebar__footer ul {
  width: 100%;
  font-size: 0.8em;
}
.sidebar__footer ul li {
  padding: 0px 20px;
}
.sidebar__footer ul li a,
.sidebar__footer ul li span {
  color: whitesmoke;
  display: block;
  font-weight: bold;
  letter-spacing: 0.25em;
  text-transform: uppercase;
  width: 100%;
}
.sidebar__footer ul li:hover {
  cursor: pointer;
  background: #2e4092;
}
.sidebar--collapsed {
  width: 50px;
  overflow: hidden;
}
.sidebar--collapsed .sidebar__header {
  /* Safari */
  -webkit-transform: rotate(-90deg);
  /* Firefox */
  -moz-transform: rotate(-90deg);
  /* IE10+ */
  transform: rotate(-90deg);
  /* Opera */
  -o-transform: rotate(-90deg);
  font-weight: bold;
}
.sidebar--collapsed .sidebar__header button {
  /* Safari */
  -webkit-transform: rotate(90deg);
  /* Firefox */
  -moz-transform: rotate(90deg);
  /* IE10+ */
  transform: rotate(90deg);
  /* Opera */
  -o-transform: rotate(90deg);
}
.sidebar--collapsed .sidebar__content {
  display: none;
}
.sidebar--collapsed .sidebar__footer {
  display: none;
}
.workspace {
  grid-area: workspace;
  flex: 1;
}
.workspace .workspace__header {
  max-height: 4em;
  font-size: 1em;
}
.workspace .workspace__status {
  font-size: 1em;
  position: fixed;
  right: 5px;
  top: 5px;
  width: 40%;
  min-height: 50px;
  padding: 5px;
  border-radius: 4px;
  z-index: 10;
}
.workspace .workspace__status-close {
  float: right;
  font-weight: bold;
  font-size: x-large;
  cursor: pointer;
}
.workspace .workspace__status--info {
  background: #fff;
  color: #333;
}
.workspace .workspace__status--success {
  background: #9bc855;
  color: #fff;
}
.workspace .workspace__status--warning {
  background: #ff9b32;
  color: #fff;
}
.workspace .workspace__status--danger {
  background: #e6003c;
  color: #fff;
}
.workspace .workspace__content {
  color: #333;
  background: #fff;
}
.workspace .workspace__footer {
  height: 4em;
  font-size: 0.8em;
}
.shell {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: 'sidebar workspace';
  height: 100vh;
}
