@import "../_variables.less";

.form {
  fieldset {
    border: none;
    margin-bottom: 10px;
  }
  legend {
    &:hover {
      cursor: pointer;
    }
  }
  label {
    display: block;
    margin-bottom: 2px;
    font-size: 0.8em;
  }
  input[type="text"],
  input[type="password"],
  input[type="date"],
  input[type="datetime"],
  input[type="email"],
  input[type="number"],
  input[type="search"],
  input[type="time"],
  input[type="url"],
  textarea,
  select {
    background: rgba(255,255,255,.1);
    border: none;
    border-radius: 4px;
    margin: 0;
    outline: 0;
    padding: 5px;
    width: 100%;
    background-color: @form-control-background-color;
    // margin-bottom: 10px;
    box-sizing: border-box;
    box-shadow: 0 1px 0 rgba(0,0,0,0.03) inset;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-shadow: 0 1px 0 rgba(0,0,0,0.03) inset;
  }
  input[type="text"]:focus,
  input[type="password"]:focus,
  input[type="date"]:focus,
  input[type="datetime"]:focus,
  input[type="email"]:focus,
  input[type="number"]:focus,
  input[type="search"]:focus,
  input[type="time"]:focus,
  input[type="url"]:focus,
  textarea:focus,
  select:focus{
    background: @form-control-background-color-focus;
  }
  select{
    -webkit-appearance: menulist-button;
    height: 35px;
  }
  .number {
    height: 30px;
    width: 30px;
    display: inline-block;
    font-size: 0.8em;
    margin-right: 4px;
    line-height: 30px;
    text-align: center;
    text-shadow: 0 1px 0 rgba(255,255,255,0.2);
    border-radius: 15px 15px 15px 0px;
  }

  &__validation--error {
    font-size: 0.8em;
    font-weight: bold;
    color: @danger-color;
    margin-bottom: 10px;
  }

  &__validation-summary {
    color: @danger-color;
    margin: 10px 0px;
    li {
      margin: 0;
    }
  }
}

.editor {
  display: block;
  padding-bottom: 10px;
}
