@import "../_variables.less";

.button__bar {
  position: relative;
  display: inline-block;
  vertical-align: middle;

  > button,
  > .button,
  > .button-wrapper {
    position: relative;
    float: left;
    margin-right: 1px;
    margin-bottom: 1px;
    border-radius: 0;

    &:first-child {
      border-top-left-radius: @button-border-radius;
      border-bottom-left-radius: @button-border-radius;
    }

    &:last-child {
      margin-right: 0;
      border-top-right-radius: @button-border-radius;
      border-bottom-right-radius: @button-border-radius;
    }
  }
}
