@import "../_variables.less";

.shell {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 1fr;
  grid-template-areas:
    'sidebar workspace';
  height: 100vh;
}

// @media (max-width: @breakpoint-max-width) {
//   .shell {
//     display: grid;
//     grid-template-columns: 1fr;
//     grid-template-rows: @sidebar-height 1fr;
//     grid-template-areas:
//       'sidebar'
//       'workspace';
//     height: 100vh;
//   }
// }
