@import "../_variables.less";

@popover-background-color: #fff;

.cdk-overlay-container {
  background-color: @popover-background-color;
  opacity: 0.97 !important;
}

.popover {
  background-color: @popover-background-color;
  box-shadow: 0 2px 14px lighten(@sidebar-background, 20%);
  border-radius: 5px;
  padding: 1rem;
}