@import "../_variables.less";

.button,
a.button,
button,
[type=button],
[type=submit] {
  display: inline-block;
  background: @button-primary-background-color;
  border: 1px solid @button-primary-background-color;
  color: white;
  text-decoration: none;
  padding: .25em 1em;
  vertical-align: middle;
  white-space: nowrap;
  cursor: pointer;
  font-size: 0.8em;
  border-radius: @button-border-radius;

  &:hover {
    background: lighten(@button-primary-background-color, 10%)
  }

  &:disabled {
    background: lighten(@button-primary-background-color, 50%)
  }
}

.button--secondary {
  background: @button-secondary-background-color;
  border: 1px solid @button-secondary-background-color;
  &:hover {
    background: lighten(@button-secondary-background-color, 10%)
  }
}

.button--squared {
  padding: .5em 0.75em;
  border-radius: 2px;
}

.button--disabled {
  background: lighten(@button-primary-background-color, 50%)
}
