@import "../_variables.less";

@base-color: @sidebar-background;
@disable-background-color: #eceeef;

.multiselect-dropdown {
  position: relative;

  .dropdown-btn {
    display: inline-block;
    border: 1px solid #adadad;
    width: 100%;
    min-height: 25px;
    // padding: 6px 6px;
    margin-bottom: 0;
    font-size: 0.9em;
    font-weight: normal;
    text-align: left;
    vertical-align: middle;
    background-color: @form-control-background-color;
    border-radius: 4px;
    cursor: pointer;

    .selected-item {
      border: 1px solid @base-color;
      margin: 4px;
      padding: 0 4px;
      border-radius: 2px;
      background: @base-color;
      color: #fff;
      float: left;

      a {
        text-decoration: none;
      }

      &:hover {
        box-shadow: 1px 1px #959595;
      }
    }

    .no-item-selected {
      margin-top: 2px;
      margin-bottom: 2px;
      padding: 1px 5px;
      float: left;
    }

    .dropdown-down {
      display: inline-block;
      top: 5px;
      width: 0;
      height: 0;
      border-top: 5px solid #adadad;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
    }

    .dropdown-up {
      display: inline-block;
      width: 0;
      height: 0;
      border-bottom: 5px solid #adadad;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
    }
  }

  .disabled {
    & > span {
      background-color: @disable-background-color;
    }
  }
}

.dropdown-list {
  position: absolute;
  width: 100%;
  z-index: 1000;
  border: 1px solid #ccc;
  background: #fff;
  box-shadow: 0px 1px 5px #959595;

  ul {
    padding: 0px;
    list-style: none;
    overflow-y: auto;
    margin: 0px;
    max-height: 200px;
  }

  li {
    padding: 5px;
    cursor: pointer;
    text-align: left;
  }

  .filter-textbox {
    border-bottom: 1px solid #ccc;
    position: relative;
    display: flex;

    input {
      border: 0;
      margin: 0;
      &:focus {
        outline: none;
      }
    }

    button {
      margin-left: 5px;
    }
  }
}

.multiselect-item-checkbox input[type='checkbox'] {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.multiselect-item-checkbox input[type='checkbox']:focus + div:before,
.multiselect-item-checkbox input[type='checkbox']:hover + div:before {
  border-color: @base-color;
  background-color: #f2f2f2;
}
.multiselect-item-checkbox input[type='checkbox'] + div {
  position: relative;
  padding-left: 2em;
  vertical-align: middle;
  user-select: none;
  cursor: pointer;
  margin: 0px;
}

.multiselect-item-checkbox input[type='checkbox'] + div:before {
  box-sizing: content-box;
  content: '';
  color: @base-color;
  position: absolute;
  top: 50%;
  left: 0;
  width: 14px;
  height: 14px;
  margin-top: -9px;
  border: 2px solid @base-color;
  text-align: center;
  transition: all 0.1s ease;
}

.multiselect-item-checkbox input[type='checkbox'] + div:after {
  background-color: @base-color;
  content: '';
  position: absolute;
  box-sizing: content-box;
  top: 50%;
  left: 4px;
  width: 10px;
  height: 10px;
  margin-top: -5px;
  transform: scale(0);
  transform-origin: 50%;
  transition: transform 100ms ease-out;
}

.multiselect-item-checkbox input[type='checkbox'] + div:after {
  background-color: transparent;
  top: 50%;
  left: 4px;
  width: 8px;
  height: 3px;
  margin-top: -4px;
  border-style: solid;
  border-color: #fff;
  border-width: 0 0 3px 3px;
  border-image: none;
  transform: rotate(-45deg) scale(0);
}

.multiselect-item-checkbox input[type='checkbox']:checked + div:after {
  content: '';
  transform: rotate(-45deg) scale(1);
  transition: transform 100ms ease-out;
}

.multiselect-item-checkbox input[type='checkbox']:checked + div:before {
  background: @base-color;
  animation: borderscale 100ms ease-in;
}

@keyframes borderscale {
  50% {
    box-shadow: 0 0 0 2px @base-color;
  }
}
