@import "./../_variables.less";

.workspace {
  grid-area: workspace;
  flex: 1;

  .workspace__header {
    max-height: 4em;
    font-size: 1em;
  }

  .workspace__status {
    font-size: 1em;
    position: fixed;
    right: 5px;
    top: 5px;
    width: 40%;
    min-height: 50px;
    padding: 5px;
    border-radius: 4px;
    z-index: 10;
    &-close {
      float: right;
      font-weight: bold;
      font-size: x-large;
      cursor: pointer;
    }
    &--info {
      background: @info-color;
      color: @font-color;
    }
    &--success {
      background: @success-color;
      color: @font-color-white;
    }
    &--warning {
      background: @warning-color;
      color: @font-color-white;
    }
    &--danger {
      background: @danger-color;
      color: @font-color-white;
    }
  }

  .workspace__content {
    color: @workspace-color;
    background: @workspace-background;
  }

  .workspace__footer {
    height: 4em;
    font-size: .8em;
  }
}
